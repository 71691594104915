<template>
  <div class="class_detail_content_sty">
    <div class="content_box_title">
      <div @click="returnPage"><i class="iconfont">&#xe625;</i>{{title}}</div>
    </div>
    <div class="content_box_card" v-if="!empty">
      <div class="content_box_card_details">
        <one-lesson
          class="one_lesson"
          v-for="lesson in list"
          :key="lesson.courseId"
          :lessonInfo="lesson"
          width="208"
          height="132"
          @click.native="goDetail(lesson.courseId)"
        />
      </div>
      <div class="content_box_card_paging">
        <el-pagination
          background
          hide-on-single-page
          layout="prev, pager, next, jumper"
          :page-size="params.pageSize"
          :current-page.sync="params.pageNum"
          :total="total"
          @current-change="appraisesPageChange"
        ></el-pagination>
      </div>
    </div>
    <div v-else class="my_collection_empty">
      <empty-status
        :img="require('@/assets/img/emptyIcon/wushouchang@2x.png')"
        :desc="'还没有课程~'"
      />
    </div>
  </div>
</template>
<script>
import { myClassCourse } from "@/api/class";
import emptyStatus from "@/components/emptyStatus/index";
import oneLesson from "@/components/oneLesson/index";
export default {
  components: {
    emptyStatus,
    oneLesson,
  },
  data() {
    return {
      params: {
        pageNum: 1,
        pageSize: 10,
        categoryId: "",
      },
      empty: false,
      list: [],
      total: 0,
      title: '',
    };
  },
  created() {
    this.title = this.$route.query.title || "我的班级";
    this.myClassCourse();
  },
  methods: {
    //返回上一页
    returnPage() {
      this.$router.push({
        path: "myStudy",
        query: { option: 1, path: "myClass" },
      });
    },
    //获取班级课程
    myClassCourse() {
      let classId = this.$route.query.id || "0";
      if (classId) {
        this.$loading.show();
        let that = this;
        myClassCourse(this.params, classId).then((res) => {
          that.$loading.hide();
          if (res.code == 200) {
            if (res.total <= 0) {
              this.empty = true;
            } else {
              this.empty = false;
            }
            this.list = res.rows;
            this.total = res.total;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    //改变页
    appraisesPageChange() {
      this.myClassCourse();
    },
    //跳转课程详情
    goDetail(courseId) {
      this.$router.replace({
        path: "/courseDetail",
        query: { courseId: courseId },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.class_detail_content_sty {
  text-align: left;
  width: 100%;
}

.content_box_title {
  padding-left: 33px;
  border-bottom: 1px solid #eeeeee;
  text-align: left;
  & > div {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 60px;
    height: 60px;
    // width: 110px;
    cursor: pointer;
    & > .iconfont {
      font-size: 18px;
      padding-right: 12px;
    }
  }
}

//课程
.content_box_card {
  padding: 29px 18px 13px;
  width: 100%;
  height: 667px;
  & > .content_box_card_details {
      height: 550px;
    ::v-deep.one_lesson {
      margin: 0 12px 25px;
    }
  }
}

//分页
.content_box_card_paging {
  padding: 20px 30px 26px;
  text-align: right;
  height: 58px;
  ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0d79ff;
  }
}

//空状态
.my_collection_empty {
  width: 100%;
  height: 408px;
}
</style>